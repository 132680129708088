.group-list {
    @apply text-black text-body-regular flex flex-col gap-1;

    &.striped li.group-list-item:nth-of-type(odd) {
        @apply bg-gray-200;
    }

    li.group-list-item {
        @apply flex items-center justify-between py-2 px-4 rounded-xl;

        &.darken {
            @apply bg-gray-400 py-3 #{!important};
        }
    }
}
