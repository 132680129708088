.breadcrumb {
    @apply flex;
}

.breadcrumb:not(.contained) {
    @apply bg-white border-b border-gray-200;
}

.breadcrumb ol {
    @apply flex space-x-4;
}

.breadcrumb:not(.contained) ol {
    @apply max-w-screen-xl w-full mx-auto px-4 sm:px-6 lg:px-8;
}

.breadcrumb.contained ol {
    @apply bg-white rounded-md shadow px-6;
}

.breadcrumb ol li {
    @apply flex;
}

.breadcrumb ol li .arrow {
    @apply flex-shrink-0 w-6 h-full text-gray-200;
}

.breadcrumb ol li .content {
    @apply flex items-center;
}

.breadcrumb ol li .content button,
.breadcrumb ol li .content a {
    @apply text-sm font-medium text-gray-400 hover:text-gray-500;
}
