.general-info,
.delivery-table {
    @apply mt-10 text-sm;

    th {
        @apply text-left;
    }

    th,
    td {
        @apply px-2.5 py-1;
    }
}

.items-table {
    @apply mt-10;

    th,
    td {
        @apply px-2.5 py-1;
    }
}
