.card {
    @apply bg-white w-full ;

    &.airy-xs {
        .card-title {
            @apply px-6 py-3;
        }

        .card-text {
            @apply px-6;
        }

        .card-actions {
            @apply px-6 py-2;
        }
    }

    &.airy-sm {
        .card-title {
            @apply px-6 py-6;
        }

        .card-text {
            @apply px-6;
        }

        .card-actions {
            @apply px-6 py-4;
        }
    }

    &.airy-md {
        .card-title {
            @apply px-6 py-8;
        }

        .card-text {
            @apply px-6;
        }

        .card-actions {
            @apply px-6 py-6;
        }
    }
}

.card.rounded {
    @apply rounded-lg;
}

.card.card-bordered {
    @apply border border-gray-300;
}

.card.disabled {
    @apply relative;
    .disabled-overlay {
        @apply absolute w-full h-full top-0 left-0 bg-gray-100 opacity-60;
    }
}

.card .card-title {
    @apply py-3.5 px-3 flex items-center justify-between flex-wrap;
    &.disabled {
        @apply relative;
        .disabled-overlay {
            @apply absolute w-full h-full top-0 left-0 bg-gray-100 opacity-60;
        }
    }
}

.card .card-title.card-title-border {
    @apply border-b border-gray-400;
}

.card .card-text {
    &.disabled {
        @apply relative;
        .disabled-overlay {
            @apply absolute w-full h-full top-0 left-0 bg-gray-100 opacity-60;
        }
    }
}

.card .card-actions {
    @apply py-3;
    &.disabled {
        @apply relative;
        .disabled-overlay {
            @apply absolute w-full h-full top-0 left-0 bg-gray-100 opacity-60;
        }
    }
}

.card .card-actions.card-actions-border {
    @apply border-t border-gray-400;
}

.card .card-actions .card-actions-container {
    @apply flex items-center flex-wrap;
}
