.toggle {
    @apply pt-2 flex flex-col justify-between;

    .toggle-container {
        @apply flex items-center gap-3;
    }

    .switch {
        @apply relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gray-200;

        &.primary {
            @apply focus:ring-primary;

            &.enabled {
                @apply bg-primary;
            }
        }

        &.secondary {
            @apply focus:ring-secondary;

            &.enabled {
                @apply bg-secondary;
            }
        }

        .dot {
            @apply translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out;
        }

        &.enabled {
            .dot {
                @apply translate-x-5;
            }
        }
    }

    .label {
        @apply text-sm font-medium text-gray-900 inline pb-0.5;
    }

    .yes-label {
        @apply text-right text-sm font-medium text-gray-900;
    }

    .no-label {
        @apply text-left text-sm font-medium text-gray-900;
    }
}
