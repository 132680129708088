.description-list {
    @apply px-4 sm:p-0;

    .description-list-item {
        @apply py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6;

        .description-list-item-title {
            @apply text-sm font-medium text-gray;
        }

        .description-list-item-value {
            @apply mt-1 text-sm text-gray sm:mt-0 sm:col-span-2;
        }
    }
    &.fluid {
        .description-list-item {
            @apply sm:grid-cols-2;
            .description-list-item-value {
                @apply sm:col-span-1;
            }
        }
    }
}
