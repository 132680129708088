.upload-area {
    @apply relative block w-full border-2 border-gray-500 border-dashed rounded-lg p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all;

    &.primary {
        @apply focus:ring-primary;
    }

    &.secondary {
        @apply focus:ring-secondary;
    }
}
