.grid-select {
    @apply max-w-[16.25rem] outline-none;

    .grid-select-title {
        @apply text-secondary font-bold text-center block text-body-regular;
    }

    .grid-select-header,
    .grid-select-footer {
        @apply text-center text-secondary mt-2.5 text-xs;
    }

    .grid-select-table {
        @apply flex justify-center mt-2.5 outline-none;

        .grid-select-table-grid {
            @apply flex flex-col outline-none;
            @apply w-[11.25rem] h-[13.75rem];
            @apply border-gray-500 border-l border-t;

            .grid-select-table-grid-row {
                @apply flex flex-1;
            }

            .grid-select-table-grid-column {
                @apply flex flex-1 justify-center items-center;
                @apply border-gray-500 border-r border-b text-xs;
                @apply text-secondary text-opacity-30;

                &.column-item-disabled {
                    @apply bg-gray-300;
                }

                &.column-item-active {
                    @apply bg-primary text-white #{!important};
                }

                &:not(.column-item-disabled):not(.column-item-readonly):hover {
                    @apply bg-primary/60 text-white;
                }

                &:not(.column-item-readonly):focus-visible {
                    @apply outline-none bg-primary/60 text-white;
                }

                .grid-select-table-grid-column-select {
                    @apply w-full h-full p-0 #{!important};

                    &:not(.is-read-only) .selector--is-disabled .selector__control {
                        @apply bg-gray-300 #{!important};
                    }

                    &.is-read-only .selector--is-disabled .selector__control {
                        @apply bg-white #{!important};

                        .selector__single-value {
                            @apply text-secondary #{!important};
                        }
                    }

                    .selector__control {
                        @apply h-[1.9rem] w-6 rounded-none border-none hover:bg-primary/10 transition-none text-center #{!important};
                        &:hover {
                            .selector__single-value {
                                @apply text-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .grid-select-close {
        @apply flex justify-end mt-3;
    }
}
