.textarea {
    @apply pt-2;
}

.textarea textarea {
    @apply shadow-sm block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray text-gray;
}

.textarea label {
    @apply block text-sm font-normal text-gray-600;
}

.textarea.primary textarea {
    @apply focus:ring-primary focus:border-primary;
}

.textarea.invalid textarea {
    @apply border-2 border-danger ring-danger focus:border-danger focus:ring-danger;
}

.textarea.invalid label {
    @apply text-danger;
}

.textarea .textarea-message {
    @apply text-gray-400 text-xs mt-1;
}

.textarea.invalid .textarea-message {
    @apply text-danger;
}

.textarea textarea:disabled {
    @apply text-gray-400 bg-gray-200 border-gray-400 #{!important};
}
