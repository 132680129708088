.mpvz-calculator-table {
    @apply text-gray-700 text-sm text-center overflow-x-auto;

    table {
        @apply w-full;
    }

    .first-table {
        td {
            @apply h-10;

            &.empty-cell {
                @apply border-l border-t border-r border-gray-300;
            }
        }
    }

    .second-table {
        td {
            width: 3.125rem;
            height: 3.125rem;
            @apply border-l border-t border-b border-gray-600;

            &.full-bordered {
                @apply border-r;
            }

            &.filled {
                @apply bg-gray-400;
            }
        }
    }

    .third-table {
        td {
            width: 4.688rem;
            @apply h-10;
            @apply border-l border-r border-b border-gray-300;

            &.auto {
                @apply w-auto #{!important};
            }
        }
    }
}
