// /*
// ###############

// RADIO BUTTON COMPONENT

// ###############
// */
.radio-button-group {
    .radio-button-group-label {
        @apply block text-sm font-medium text-gray-700 pb-2 flex flex-col;
    }

    .radio-button-group-container {
        @apply space-y-5;
    }

    .radio-button-group-message {
        @apply text-gray-500 text-xs block pt-1;
    }

    &.invalid {
        .radio-button-group-message {
            @apply text-danger;
        }

        .radio-button-group-label {
            @apply text-danger;
        }
    }
}






