.price-offer-step-one-example-table {
    tr:not(:last-child) {
        td {
            @apply border-b-2 border-gray-400;
            &:not(:last-child) {
                @apply border-r-2;
            }
        }
    }
    tr:last-child {
        td:not(:last-child) {
            @apply border-r-2 border-gray-400;
        }
    }
}
