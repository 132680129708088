/* GRID */
.cols-auto {
    width: auto;
    max-width: unset;
}

.cols-1 {
    width: 8.33333333333%;
    max-width: 8.33333333333%;
}

.cols-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
}

.cols-3 {
    width: 25%;
    max-width: 25%;
}

.cols-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
}

.cols-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
}

.cols-6 {
    width: 50%;
    max-width: 50%;
}

.cols-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
}

.cols-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
}

.cols-9 {
    width: 75%;
    max-width: 75%;
}

.cols-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
}

.cols-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
}

.cols-12 {
    width: 100%;
    max-width: 100%;
}

@screen sm {
    .cols-sm-auto {
        width: auto !important;
        max-width: auto !important;
    }

    .cols-sm-1 {
        width: 8.33333333333% !important;
        max-width: 8.33333333333% !important;
    }

    .cols-sm-2 {
        width: 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }

    .cols-sm-3 {
        width: 25% !important;
        max-width: 25% !important;
    }

    .cols-sm-4 {
        width: 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }

    .cols-sm-5 {
        width: 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }

    .cols-sm-6 {
        width: 50% !important;
        max-width: 50% !important;
    }

    .cols-sm-7 {
        width: 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }

    .cols-sm-8 {
        width: 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }

    .cols-sm-9 {
        width: 75% !important;
        max-width: 75% !important;
    }

    .cols-sm-10 {
        width: 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }

    .cols-sm-11 {
        width: 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }

    .cols-sm-12 {
        width: 100% !important;
        max-width: 100% !important;
    }
}
@screen md {
    .cols-md-auto {
        width: auto !important;
        max-width: auto !important;
    }

    .cols-md-1 {
        width: 8.33333333333% !important;
        max-width: 8.33333333333% !important;
    }

    .cols-md-2 {
        width: 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }

    .cols-md-3 {
        width: 25% !important;
        max-width: 25% !important;
    }

    .cols-md-4 {
        width: 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }

    .cols-md-5 {
        width: 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }

    .cols-md-6 {
        width: 50% !important;
        max-width: 50% !important;
    }

    .cols-md-7 {
        width: 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }

    .cols-md-8 {
        width: 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }

    .cols-md-9 {
        width: 75% !important;
        max-width: 75% !important;
    }

    .cols-md-10 {
        width: 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }

    .cols-md-11 {
        width: 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }

    .cols-md-12 {
        width: 100% !important;
        max-width: 100% !important;
    }
}
@screen lg {
    .cols-lg-auto {
        width: auto !important;
        max-width: auto !important;
    }

    .cols-lg-1 {
        width: 8.33333333333% !important;
        max-width: 8.33333333333% !important;
    }

    .cols-lg-2 {
        width: 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }

    .cols-lg-3 {
        width: 25% !important;
        max-width: 25% !important;
    }

    .cols-lg-4 {
        width: 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }

    .cols-lg-5 {
        width: 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }

    .cols-lg-6 {
        width: 50% !important;
        max-width: 50% !important;
    }

    .cols-lg-7 {
        width: 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }

    .cols-lg-8 {
        width: 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }

    .cols-lg-9 {
        width: 75% !important;
        max-width: 75% !important;
    }

    .cols-lg-10 {
        width: 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }

    .cols-lg-11 {
        width: 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }

    .cols-lg-12 {
        width: 100% !important;
        max-width: 100% !important;
    }
}
@screen xl {
    .cols-xl-auto {
        width: auto !important;
        max-width: auto !important;
    }

    .cols-xl-1 {
        width: 8.33333333333% !important;
        max-width: 8.33333333333% !important;
    }

    .cols-xl-2 {
        width: 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }

    .cols-xl-3 {
        width: 25% !important;
        max-width: 25% !important;
    }

    .cols-xl-4 {
        width: 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }

    .cols-xl-5 {
        width: 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }

    .cols-xl-6 {
        width: 50% !important;
        max-width: 50% !important;
    }

    .cols-xl-7 {
        width: 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }

    .cols-xl-8 {
        width: 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }

    .cols-xl-9 {
        width: 75% !important;
        max-width: 75% !important;
    }

    .cols-xl-10 {
        width: 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }

    .cols-xl-11 {
        width: 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }

    .cols-xl-12 {
        width: 100% !important;
        max-width: 100% !important;
    }
}
@screen 2xl {
    .cols-2xl-auto {
        width: auto !important;
        max-width: auto !important;
    }

    .cols-2xl-1 {
        width: 8.33333333333% !important;
        max-width: 8.33333333333% !important;
    }

    .cols-2xl-2 {
        width: 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }

    .cols-2xl-3 {
        width: 25% !important;
        max-width: 25% !important;
    }

    .cols-2xl-4 {
        width: 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }

    .cols-2xl-5 {
        width: 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }

    .cols-2xl-6 {
        width: 50% !important;
        max-width: 50% !important;
    }

    .cols-2xl-7 {
        width: 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }

    .cols-2xl-8 {
        width: 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }

    .cols-2xl-9 {
        width: 75% !important;
        max-width: 75% !important;
    }

    .cols-2xl-10 {
        width: 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }

    .cols-2xl-11 {
        width: 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }

    .cols-2xl-12 {
        width: 100% !important;
        max-width: 100% !important;
    }
}
