table.plain-table.sticky-table,
.plain-table.sticky-table table {
    thead {
        @apply sticky top-0 z-[100] #{!important};
    }

    tfoot {
        @apply sticky bottom-0 z-[100] #{!important};
    }
}

.plain-table.table-center td,
.plain-table.table-center th {
    @apply text-center #{!important};
}

table.plain-table.compact,
.plain-table.compact table {
    th,
    td {
        @apply leading-relaxed h-auto py-2.5;
    }
}

table.hover-effect.plain-table,
.plain-table.hover-effect table {
    tbody {
        tr:not(.disabled-hover-effect):hover td:not(.sticky-col) {
            @apply bg-yellow-50 #{!important};
        }
    }
}

table.plain-table.striped,
.plain-table.striped table {
    tr:nth-child(even) {
        td {
            @apply bg-gray-200;
        }
    }
}

table.plain-table,
.plain-table table {
    table-layout: fixed;
    @apply border-separate border-spacing-0;

    thead th,
    tfoot td,
    tbody td {
        line-height: 1.325;
    }

    // tr th {
    //   @apply border-b border-secondary;
    // }

    tfoot {
        tr:first-child td,
        tr:first-child th {
            @apply border-secondary border-t;
        }
    }

    th,
    td {
        @apply text-left text-body-small;
        @apply px-3.5 py-3.5 #{!important};
        @apply border-gray-400;
    }

    th {
        @apply border-secondary pt-2;
    }

    th {
        @apply text-white font-normal text-xs leading-tight h-10;
        @apply print:text-secondary print:border-secondary;
        &:not(:first-child) {
            @apply print:border-l;
        }
    }

    thead {
        @apply bg-gray-600 rounded-xl print:bg-white;
    }

    tr {
        td {
            // @apply bg-gray-200;
            @apply print:text-secondary print:border-secondary;
            &:not(:first-child) {
                @apply print:border-l;
            }
        }

        &.darken-row {
            td {
                @apply bg-white;
            }
        }
        &.light-row {
            td {
                @apply bg-gray-200;
            }
        }
    }
    td {
        @apply text-blue-300 font-normal text-xs;
        min-height: 3.5rem;
        height: 3.5rem;
    }

    tfoot {
        @apply bg-secondary-100 text-gray-500;
    }
}
