/*
###############

LOGIN CARD COMPONENT

###############
*/
.login-card {
    @apply mt-8 sm:mx-auto sm:w-full sm:max-w-md;
}

.login-card .social-site-section {
    @apply mt-6 grid grid-cols-3 gap-3;
}

.login-card .wrapper {
    @apply bg-white;
}
