dl.details {
    @apply grid grid-cols-1 gap-x-4 gap-y-8;
}

dl.details.g-cols-1 {
    @apply sm:grid-cols-1;
}

dl.details.g-cols-2 {
    @apply sm:grid-cols-2;
}

dl.details.g-cols-3 {
    @apply sm:grid-cols-3;
}

dl.details.g-cols-4 {
    @apply sm:grid-cols-4;
}

dl.details.g-cols-5 {
    @apply sm:grid-cols-5;
}

dl.details.g-cols-6 {
    @apply sm:grid-cols-6;
}

dl.details.g-cols-7 {
    @apply sm:grid-cols-7;
}

dl.details.g-cols-8 {
    @apply sm:grid-cols-8;
}

dl.details.g-cols-9 {
    @apply sm:grid-cols-9;
}

dl.details.g-cols-10 {
    @apply sm:grid-cols-10;
}

dl.details.g-cols-11 {
    @apply sm:grid-cols-11;
}

dl.details.g-cols-12 {
    @apply sm:grid-cols-12;
}

dl.details .item {
    @apply sm:col-span-1;
}

dl.details .item dt {
    @apply text-sm font-medium text-gray-500;
}

dl.details .item dd {
    @apply mt-1 text-sm text-gray-900;
}
