.modal {
    @apply fixed z-30 inset-0 overflow-y-auto;
}

.modal .modal-title {
    @apply text-subtitle-small leading-6 font-normal text-gray;
}

.modal .modal-text {
    @apply mt-2 text-body-small font-normal text-gray-500;
}

.modal .modal-text p {
    @apply text-gray-500;
}

.modal .modal-actions {
    @apply mt-5 sm:mt-6 flex;
}

.modal .wrapper {
    @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.modal .wrapper .modal-container {
    @apply inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6;
}

.modal.simple-variant .modal-container .modal-container-wrapper {
    @apply sm:flex sm:items-start;
}

.modal .wrapper .modal-container .modal-content {
    @apply mt-3 text-center sm:mt-5;
}

.modal.centered-variant .wrapper .modal-container .modal-content {
    @apply sm:mt-5;
}

.modal.simple-variant .wrapper .modal-container .modal-content {
    @apply sm:mt-0 sm:ml-4 sm:text-left;
}

.modal .modal-overlay {
    @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.modal .modal-icon {
    @apply mx-auto flex items-center justify-center h-12 w-12 rounded-full text-gray;
}

.modal.simple-variant .modal-icon {
    @apply flex-shrink-0 sm:mx-0 sm:h-10 sm:w-10;
}

.modal .modal-icon.primary {
    @apply bg-primary text-white;
}

.modal .modal-icon.secondary {
    @apply bg-secondary text-white;
}
