.notification {
    @apply fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40;
}

.notification .notification-icon {
    @apply flex-shrink-0;
}

.notification.primary .notification-icon {
    @apply text-primary;
}

.notification.secondary .notification-icon {
    @apply text-secondary;
}

.notification .wrapper .notification-close-button {
    @apply bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.notification.primary .wrapper .notification-close-button {
    @apply focus:ring-primary;
}

.notification.secondary .wrapper .notification-close-button {
    @apply focus:ring-secondary;
}

.notification .wrapper {
    @apply w-full flex flex-col items-center space-y-4 sm:items-end;
}

.notification .wrapper .notification-title {
    @apply text-sm font-medium text-gray-900;
}

.notification .wrapper .notification-text {
    @apply mt-1 text-sm text-gray-500;
}

.notification .wrapper .notification-container {
    @apply max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;
}
