.stepper {
    @apply rounded-md divide-y divide-gray-400 md:flex md:divide-y-0;

    .stepper-list {
        @apply relative md:flex-1 md:flex;

        .step-variant-current {
            @apply px-6 py-4 flex items-center text-body-small font-medium;

            .step-id-container {
                @apply flex-shrink-0 w-10 h-10 flex items-center justify-center border rounded-full border-primary;

                .step-id {
                    @apply text-primary;
                }
            }

            .step-name {
                @apply ml-4 text-body-small font-medium text-primary;
            }
        }

        .step-variant-complete {
            @apply flex items-center w-full;

            .step-container {
                @apply px-6 py-4 flex items-center text-body-small font-medium;

                .step-icon-container {
                    @apply flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full border border-gray-500 bg-white;

                    .step-icon {
                        @apply w-8 h-8 text-gray-500;
                    }
                }

                .step-name-container {
                    @apply ml-4 text-body-small font-medium text-gray-500 #{!important};
                }
            }
        }

        .step-variant-normal {
            @apply flex items-center;

            .step-container {
                @apply px-6 py-4 flex items-center text-body-small font-medium;

                .step-id-container {
                    @apply flex-shrink-0 w-10 h-10 flex items-center justify-center border border-gray-500 rounded-full group-hover:border-gray-400;

                    .step-id {
                        @apply text-gray-500 group-hover:text-gray-500;
                    }
                }

                .step-name {
                    @apply ml-4 text-body-small font-medium text-gray-500 group-hover:text-gray-500;
                }
            }
        }
    }

    .step-border {
        @apply hidden md:block absolute top-0 right-0 h-full w-14;
    }
}
