/*
###############

OVERLAY COMPONENT

###############
*/
.overlay {
    @apply h-full w-full fixed top-0 bg-gray-500 bg-opacity-60 transition-opacity z-50;
}
