.toast-container {
    .Toastify__toast {
        @apply flex items-center py-3 px-3.5 gap-2.5 rounded-xl w-full max-w-lg text-body-regular;
    }

    button[aria-label='close'] {
        @apply hidden;
    }

    /* DEFAULT VERSION */
    .Toastify__toast--default {
        @apply text-white bg-gray-500;

        .Toastify__toast-icon svg {
            @apply fill-primary;
        }

        .Toastify__progress-bar {
            @apply bg-primary;
        }

        .Toastify__spinner {
            @apply border-white border-r-primary;
        }

        .body {
            @apply items-start;
        }
    }

    /* SUCCESS VERSION */
    .Toastify__toast--success {
        @apply text-success bg-success-100;

        .Toastify__toast-icon svg {
            @apply fill-success;
        }

        .Toastify__progress-bar {
            @apply bg-success;
        }

        .Toastify__spinner {
            @apply border-success border-r-white;
        }

        .body {
            @apply items-start;
        }
    }

    /* ERROR VERSION */
    .Toastify__toast--error {
        @apply text-danger bg-danger-100;

        .Toastify__toast-icon svg {
            @apply fill-danger;
        }

        .Toastify__progress-bar {
            @apply bg-danger;
        }

        .Toastify__spinner {
            @apply border-danger border-r-white;
        }

        .body {
            @apply items-start;
        }
    }

    /* INFO VERSION */
    .Toastify__toast--info {
        @apply text-info bg-info-100;

        .Toastify__toast-icon svg {
            @apply fill-info;
        }

        .Toastify__progress-bar {
            @apply bg-info;
        }

        .Toastify__spinner {
            @apply border-info border-r-white;
        }

        .body {
            @apply items-start;
        }
    }

    /* WARNING VERSION */
    .Toastify__toast--warning {
        @apply text-warning bg-warning-100;

        .Toastify__toast-icon svg {
            @apply fill-warning;
        }

        .Toastify__progress-bar {
            @apply bg-warning;
        }

        .Toastify__spinner {
            @apply border-warning border-r-white;
        }

        .body {
            @apply items-start;
        }
    }
}
