.button {
    @apply inline-flex items-center justify-center;
    @apply font-normal;
    @apply focus:outline-none;
    @apply cursor-pointer transition-colors duration-200;
}

.button.variant-text {
    @apply p-0;
    @apply font-normal;
}

.button .icon {
    @apply block;

    &.left {
        @apply -ml-1 mr-2;
    }

    &.right {
        @apply -mr-1 ml-2;
    }

    svg {
        @apply text-current w-full h-full;
    }
}
