.date-picker {
    @apply w-full;

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        @apply block #{!important};
    }

    .react-datepicker-popper {
        @apply z-20;
    }

    .react-datepicker__navigation-icon {
        top: 6px;
        &::before {
            @apply border-gray-500;
        }
    }

    .react-datepicker__day--today {
        @apply bg-info-100 rounded;
        &:hover {
            @apply bg-info-200;
        }
    }

    .react-datepicker__day--keyboard-selected {
        @apply bg-info-200;
        &:hover {
            @apply bg-info-200;
        }
    }

    .react-datepicker__day--selected {
        @apply bg-primary-400 text-white;
        &:hover {
            @apply bg-primary-400;
        }
    }

    .react-datepicker__header {
        @apply bg-info-100;
        .react-datepicker__current-month {
            @apply text-gray-600;
        }

        .react-datepicker__day-name {
            @apply text-info-400 font-semibold;
        }
    }
}
