.post-info-card-list {
    .post-info-card-list-item {
        @apply w-full;

        .post-info-card-list-item-title {
            @apply flex flex-col card-headline;
        }

        .post-info-card-list-item-description {
            @apply text-xs mt-2.5;
        }

        .post-info-card-list-item-content {
            img {
                @apply object-contain w-full #{!important};
            }
        }
    }
}
