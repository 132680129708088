@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.text-editor {
    @apply relative border border-gray-300 rounded-[0.313rem] bg-white;
    font-family: 'Rubik', sans-serif;

    .public-DraftEditor-content {
        padding: 0 8px;
        height: 300px;
        overflow-y: auto;
    }

    .rdw-option-wrapper {
        @apply border border-gray-300 rounded-md p-3 mx-1 bg-gray-100;
    }

    .rdw-option-active {
        @apply bg-gray-300;
    }

    .rdw-option:hover {
        @apply cursor-pointer;
    }

    .rdw-option i {
        @apply text-gray-600;
    }

    .rdw-dropdown-wrapper {
        @apply border border-gray-400 rounded-md h-[26px] mx-1 min-w-[70px] bg-gray-100;
    }

    .rdw-dropdown-selectedtext {
        @apply text-gray-600;
    }

    .rdw-dropdown-optionwrapper {
        @apply bg-white border border-gray-300 rounded mt-1 shadow-lg;
    }

    .rdw-dropdown-option {
        @apply text-gray-600 py-1 px-3 cursor-pointer;
    }

    .rdw-dropdown-option:hover {
        @apply bg-gray-200;
    }

    .rdw-option-wrapper,
    .rdw-dropdown-wrapper {
        @apply mx-0.5;
    }

    .rdw-link-modal {
        @apply bg-gray-100 border border-gray-400 rounded-md shadow-md p-2 h-[220px];
    }

    .rdw-link-dropdownoption {
        @apply bg-white hover:bg-gray-200 px-2 py-1;
    }

    .rdw-link-dropdown {
        @apply border border-gray-300 rounded;
    }

    .rdw-link-dropdown:hover {
        @apply bg-gray-200;
    }

    .rdw-link-modal-label {
        @apply text-sm font-bold;
        &:after {
            content: ':';
        }
    }

    .rdw-link-modal-input {
        @apply border border-gray-600 rounded-lg w-full px-2 py-1 mb-4;
    }

    .rdw-link-modal-button {
        @apply bg-gray-300 hover:bg-gray-400 text-white py-1 px-3;
    }

    .rdw-link-modal-target-option {
        @apply mb-3;
        input {
            @apply text-primary -mt-0.5 outline-none ring-0 rounded;
        }
    }

    .rdw-link-modal-btn {
        @apply rounded-lg border-0;

        &:hover {
            box-shadow: none;
        }
    }

    .rdw-link-modal-btn:disabled {
        @apply bg-gray-300 text-gray-500 #{!important};
    }

    .rdw-link-modal-btn:first-child {
        @apply bg-primary text-white;
    }

    .rdw-link-modal-btn:last-child {
        @apply bg-white border-primary border text-primary;
    }

    h1 {
        @apply text-4xl font-bold text-gray-900;
    }

    h2 {
        @apply text-3xl font-semibold text-gray-900;
    }

    h3 {
        @apply text-2xl font-semibold text-gray-900;
    }

    h4 {
        @apply text-xl font-semibold text-gray-900;
    }

    h5 {
        @apply text-lg font-medium text-gray-900;
    }

    h6 {
        @apply text-base font-medium text-gray-900;
    }
}
