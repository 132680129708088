.badge {
    @apply h-[26px] inline-flex items-center justify-center font-normal rounded-lg px-2.5 py-1 whitespace-nowrap;
    font-size: 12px;
}

.badge .icon {
    @apply block w-4 h-4;

    &.left {
        @apply -ml-0.5 mr-1;
    }

    &.right {
        @apply -mr-0.5 ml-1;
    }

    svg {
        @apply text-current w-full h-full;
    }
}

.black-badge-text .badge {
    @apply text-black #{!important};
}
