.pagination {
    @apply bg-white flex items-center justify-between;
}

.pagination.bordered {
    @apply border-t border-gray-200;
}

.pagination.paddingX {
    @apply sm:px-6 px-4;
}

.pagination.paddingY {
    @apply py-3;
}

.pagination nav {
    @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px;
}

.pagination .pagination-button {
    @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium;
}

.pagination .pagination-button:disabled {
    @apply bg-gray-100 border-gray-300 text-gray-500 #{!important};
}

.pagination .pagination-button.no-hover-effect {
    @apply hover:bg-white cursor-default;
}

.pagination .pagination-button.sm {
    @apply px-2 py-2;
}

.pagination .pagination-mobile {
    @apply flex-1 flex justify-between sm:hidden;
}

.pagination .pagination-computer {
    @apply hidden sm:flex-1 sm:flex sm:items-center sm:justify-between;
}

.pagination .pagination-computer .pagination-info {
    @apply text-sm text-gray-700;
}

.pagination .pagination-computer .pagination-info span {
    @apply font-medium;
}

.pagination.primary .pagination-button.active {
    @apply z-10 bg-primary border-primary text-white;
}

.pagination.secondary .pagination-button.active {
    @apply z-10 bg-secondary border-secondary text-white;
}
