@import 'react-phone-number-input/style.css';

.PhoneInput {
    @apply relative block #{!important};
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--primary-color) #{!important};
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--primary-color) #{!important};
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow:
        0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--primary-color),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--primary-color) #{!important};
}

.PhoneInputCountry {
    @apply left-2 absolute h-full #{!important};
}

.PhoneInputCountrySelect {
    @apply w-6 px-0 #{!important};
}

.PhoneInputInput,
.text-field.sm input.PhoneInputInput {
    @apply pl-10 #{!important};
}
