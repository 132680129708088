.simple-card {
    @apply relative flex flex-col border border-gray-300 bg-white h-full;

    &.simple-card-border {
        @apply border border-gray-300;
    }

    &.simple-card-shadow {
        @apply shadow-lg;
    }

    &.simple-card-rounded {
        @apply rounded-lg;
    }

    .simple-card-title {
        @apply block text-sm font-medium text-gray-900;

        .simple-card-title-container {
            @apply block p-4;
        }
    }

    .simple-card-image {
        @apply border-y border-gray-300;

        img {
            @apply h-48 w-full object-cover;
        }
    }

    .simple-card-description {
        @apply text-sm text-gray-500;

        .simple-card-description-container {
            @apply block p-4;
        }
    }
}
