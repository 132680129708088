.progress-bar {
    @apply rounded-md w-full bg-gray-100 py-1 relative;

    .finished-line {
        @apply rounded-md h-full absolute top-0;
    }

    &.primary {
        .finished-line {
            @apply bg-primary;
        }
    }

    &.secondary {
        .finished-line {
            @apply bg-secondary;
        }
    }
}
