.button-group {
    @apply relative z-0 inline-flex shadow-sm rounded-md;

    .button-group-item {
        @apply relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-500 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1;
    }

    .button-group-item.middle {
        @apply -ml-px rounded-l-none;
    }

    .button-group-item.last {
        @apply -ml-px rounded-l-none rounded-r-md;
    }

    .button-group-item.single {
        @apply -ml-px rounded-r-md;
    }

    &.primary {
        .button-group-item,
        .button-group-item-last {
            @apply focus:ring-primary focus:border-primary;
        }
    }

    &.secondary {
        .button-group-item,
        .button-group-item-last {
            @apply focus:ring-secondary focus:border-secondary;
        }
    }
}
