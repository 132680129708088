/*
###############

SIDEBAR COMPONENT

###############
*/
.sidebar .mobile {
    @apply mt-5 flex-1 h-0 overflow-y-auto bg-gray-100;
}

.sidebar .computer {
    @apply flex-grow pt-5 flex flex-col bg-gray-100 w-[229px];
}

.sidebar .mobile nav {
    @apply px-2 space-y-1;
}

.sidebar .computer nav {
    @apply flex-1 px-2 pb-4 space-y-1;
}

.sidebar .computer nav div:nth-child(8) {
    @apply mt-auto #{!important};
}

.sidebar .item {
    @apply rounded-lg mx-2 py-3.5 px-3 flex items-center text-[14.5px] leading-none h-[41px];
}

.sidebar .item.active {
    @apply rounded-lg bg-primary-400 text-white #{!important};
}

.sidebar .item.inactive {
    @apply text-gray-800 font-normal hover:bg-gray-300 hover:text-black;
}

.sidebar .item .icon {
    @apply  flex-shrink-0 ;
}

.sidebar .close-button {
    @apply ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white;
}
