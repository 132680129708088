.pricing-document {
    table {
        th,
        td {
            @apply text-left;
            padding: 6px 12px;
        }
    }

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
}
