.avatar.image {
    @apply flex flex-col justify-center items-center;
}

.avatar.text {
    @apply inline-flex items-center justify-center;
}

.avatar.primary.text {
    @apply bg-primary;
}

.avatar.secondary.text {
    @apply bg-secondary;
}

.avatar.circular-variant {
    @apply rounded-full;
}

.avatar.rounded-variant {
    @apply rounded-md;
}

.avatar.sm.text {
    @apply text-xs font-medium leading-none text-white;
}

.avatar.md.text {
    @apply text-sm font-medium leading-none text-white;
}

.avatar.lg.text {
    @apply font-medium leading-none text-white;
}

.avatar.xl.text {
    @apply text-lg font-medium leading-none text-white;
}

.avatar.xl2.text {
    @apply text-xl font-medium leading-none text-white;
}

.avatar.sm {
    @apply h-6 w-6;
}

.avatar.md {
    @apply h-8 w-8;
}

.avatar.lg {
    @apply h-10 w-10;
}

.avatar.xl {
    @apply h-16 w-16;
}

.avatar.xl2 {
    @apply h-20 w-20;
}
