.post-card {
    @apply flex flex-col rounded-lg overflow-hidden shadow-[0px_0px_8px_0px_rgba(26,32,44,0.10)];

    .post-card-image-container {
        @apply flex-shrink-0;

        .post-card-image {
            @apply h-48 w-full object-cover;
        }
    }

    .post-card-container {
        @apply flex-1 bg-white p-6 flex flex-col justify-between;

        .post-card-title {
            @apply text-xl font-semibold text-gray-900;
        }

        .post-card-description {
            @apply mt-3 text-base text-gray-500;
        }
    }
}
